<template>
  <v-card>
    <v-card-text class="d-flex align-center justify-space-between">
      <v-card-title class="pl-2 pt-3">
        Log de erro
      </v-card-title>
      <div
        class="d-flex"
        style="gap: 20px;"
      >
        <v-btn
          class=""
          outlined
          :color="filterButtonColor"
          @click="openModalFilterErrorLogs"
        >
          <v-icon
            size="22"
            class="me-1"
          >
            {{ icons.mdiFilterVariant }}
          </v-icon>
          <span class="button-text-logs">FILTRAR</span>
        </v-btn>
        <v-btn
          v-if="isFiltered"
          outlined
          color="error"
          @click="clearBtn"
        >
          <div
            class="d-flex align-end justify-center pb-1"
            style="gap: 10px;"
          >
            <v-icon size="22">
              {{ icons.mdiFilterVariantRemove }}
            </v-icon>
            <span class="button-text-logs">LIMPAR</span>
          </div>
        </v-btn>
        <v-btn
          v-if="isFiltered"
          class="me-3"
          outlined
          color="warning"
          :disabled="isLoadingExport"
          @click="exportCsv()"
        >
          <div
            v-if="!isLoadingExport"
            class=" d-flex items-center align-center"
            style=" gap: 10px;"
          >
            <span class="button-text-logs">
              EXPORTAR
            </span>
            <v-img
              height="17"
              width="20"
              :src="mdiTrayArrowDown"
            ></v-img>
          </div>
          <v-progress-circular
            v-else
            color="warning"
            indeterminate
          >
          </v-progress-circular>
        </v-btn>
      </div>
    </v-card-text>
    <div
      v-if="isFiltered"
      class="d-flex pb-6"
      style="gap: 13px;"
    >
      <v-card-text
        class="pr-0 mr-0 ml-2"
        style="width: 40px; height: 22px"
      >
        <v-img
          height="22"
          width="20"
          :src="mdiCalendarFilter"
        ></v-img>
      </v-card-text>
      <span>PERÍODO: {{ formatPeriod() }}</span>
    </div>
    <v-data-table
      :headers="headers"
      loading-text="Carregando dados..."
      class="text-no-wrap"
      show-expand
      disable-sort
      :loading="isLoading"
      :expanded.sync="expanded"
      :items="filteredData"
      :options.sync="options"
      :server-items-length="totalItems"
      @click:row="toggleRow"
      @update:options="onOptionsUpdate"
    >
      <template v-slot:item.user.name="{ item }">
        <div
          class=" d-flex align-center"
          style=" gap: 10px;"
        >
          <v-avatar
            size="30"
            color="error"
            class="v-avatar-light-bg success--text"
          >
            <v-img
              v-if="item.user && item.user.base64"
              :src="item.user.base64"
            ></v-img>
            <span
              v-else
              class="v-avatar-light-bg success--text"
            >
              {{ getInitials(item.user) }}
            </span>
          </v-avatar>
          <span>
            {{ item.user && item.user.name ? item.user.name : '-' }}
          </span>
        </div>
      </template>
      <template v-slot:item.created_at="{ item }">
        <span>{{ dateFormat(item.created_at) }}</span>
      </template>
      <template #expanded-item="{ item }">
        <td
          :colspan="headers.length + 1"
          style=" width: 100%; padding-right: 0px; margin-right: 0px"
        >
          <v-card-text class="d-flex flex-column pl-3">
            <div
              class="d-flex align-start pt-5"
              style="gap: 10px"
            >
              <v-icon
                color="primary"
                size="20"
              >
                {{ icons.mdiTextRecognition }}
              </v-icon>
              <div class="d-flex flex-column">
                <span>MENSAGEM DE ERRO:</span>
                <div
                  class="result-container d-flex align-center justify-center mb-3 mt-3 px-2 py-3"
                  @click="errorMessageExpand = !errorMessageExpand"
                >
                  <span
                    :class="{'expanded': errorMessageExpand, 'hover-effect': true}"
                    class="px-2 stack-trace"
                    style="color: white;"
                  >
                    {{ item.error_message === undefined ? '-' : item.error_message }}
                  </span>
                </div>
              </div>
            </div>
            <div
              class="d-flex align-start pt-5"
              style="gap: 10px"
            >
              <v-icon
                color="primary"
                size="20"
              >
                {{ icons.mdiCodeTags }}
              </v-icon>
              <div class="d-flex flex-column">
                <span>ARQUIVO:</span>
                <div
                  class="result-container d-flex align-center justify-center mb-3 mt-3 px-2 py-3"
                  @click="fileExpand = !fileExpand"
                >
                  <span
                    :class="{'expanded': fileExpand, 'hover-effect': true}"
                    class="px-2 stack-trace"
                    style="color: white;"
                  >
                    {{ item.file === undefined ? '-' : item.file }}
                  </span>
                </div>
              </div>
            </div>
            <div
              class="d-flex align-start pt-5"
              style="gap: 10px"
            >
              <img
                :src="mdiCodeBlockTags"
                height="20px"
                width="20px"
                alt=""
              />

              <div class="d-flex flex-column">
                <span>RASTREIO DE PILHA:</span>
                <div
                  class="result-container d-flex align-center justify-center mb-3 mt-3 px-2 py-3"
                  @click="stack_traceExpand = !stack_traceExpand"
                >
                  <span
                    :class="{'expanded': stack_traceExpand, 'hover-effect': true}"
                    class="px-2 stack-trace"
                    style="color: white;"
                  >
                    {{ item.expandedItemData === undefined ? '-' : item.expandedItemData.stack_trace }}
                  </span>
                </div>
              </div>
            </div>
            <div
              class="d-flex align-start pt-5"
              style="gap: 10px"
            >
              <v-icon
                color="primary"
                size="20"
              >
                {{ icons.mdiCodeJson }}
              </v-icon>
              <div class="d-flex flex-column">
                <span>EXCEÇÃO:</span>
                <div
                  class="result-container d-flex align-center justify-center mb-3 mt-3 px-2 py-3"
                  @click="exceptionExpand = !exceptionExpand"
                >
                  <span
                    :class="{'expanded': exceptionExpand, 'hover-effect': true}"
                    class="px-2 stack-trace"
                    style="color: white;"
                  >
                    {{ item.exception === null ? '-' : item.exception }}
                  </span>
                </div>
              </div>
            </div>
          </v-card-text>
        </td>
      </template>
      <template v-slot:no-data>
        <div
          style="height: 658px; font-weight: 500; gap: 56px;"
          class="d-flex align-center justify-center flex-column"
        >
          <div class="d-flex flex-column">
            <span
              style="font-size: 18px; font-weight: 500"
              :class="{'dark-mode': isDark, 'light-mode': !isDark}"
            >Nenhum registro encontrado!</span>
            <span
              style="font-size: 14px; font-weight: 500"
              :class="{'dark-mode': isDark, 'light-mode': !isDark}"
            >Por favor, verifique os filtros de pesquisa.</span>
          </div>
          <img
            :src="computedSrc"
            alt="Imagem de um bonequinho com uma lupa procurando por dados em um servidor"
          >
        </div>
      </template>
    </v-data-table>
    <v-dialog
      v-model="ModalFilterErrorLogsIsOpen"
      width="512"
      persistent
    >
      <ModalFilterErrorLogs
        ref="ModalFilterErrorLogs"
        :users-list="usersList"
        @close="ModalFilterErrorLogsIsOpen = false"
        @filteredData="$event => filteredData = $event"
        @filteredDataBody="$event => filteredDataBody = $event"
        @getFilter="getFilteredDataPaginated"
        @isFiltered="$event => isFiltered = $event"
      />
    </v-dialog>
  </v-card>
</template>

<script>
import { getVuetify } from '@/@core/utils'
import mdiCalendarFilter from '@/assets/images/svg/mdiCalendarFilter.svg'
import mdiTrayArrowDown from '@/assets/images/svg/mdiTrayArrowDown.svg'
import noDataImageLight from '@/assets/images/svg/no-data-logs-light.svg'
import noDataImageDark from '@/assets/images/svg/no-data-logs.svg'
import mdiCodeBlockTags from '@/assets/mdiCodeBlockTags.svg'
// eslint-disable-next-line import/no-cycle
import formatters from '@/plugins/formattersMixin1'
// eslint-disable-next-line import/no-cycle
import axiosNewInstance from '@/plugins/newAxiosInstance'
import {
  mdiCodeJson,
  mdiCodeTags,
  mdiFilterVariant, mdiFilterVariantRemove, mdiTextRecognition,
} from '@mdi/js'
import { saveAs } from 'file-saver'
import localStorageSlim from 'localstorage-slim'
import moment from 'moment'
import ModalFilterErrorLogs from './ModalFilterErrorLogs.vue'

const ExcelJS = require('exceljs')

export default {
  name: 'LogsError',
  components: {
    ModalFilterErrorLogs,
  },
  mixins: [formatters],
  data() {
    return {
      noDataImageLight,
      mdiTrayArrowDown,
      noDataImageDark,
      mdiCalendarFilter,
      mdiCodeBlockTags,
      stack_traceExpand: false,
      errorMessageExpand: false,
      fileExpand: false,
      exceptionExpand: false,
      ModalFilterErrorLogsIsOpen: false,
      loadingItem: false,
      isFiltered: false,
      isLoadingExport: false,
      status: 0,
      isLoading: false,
      options: {
        page: 1,
        itemsPerPage: 15,
      },
      totalItems: 0,

      icons: {
        mdiFilterVariant, mdiFilterVariantRemove, mdiTextRecognition, mdiCodeTags, mdiCodeBlockTags, mdiCodeJson,
      },
      headers: [
        { text: 'USUÁRIO', value: 'user.name', align: '' },
        { text: 'DATA E HORA', value: 'created_at', align: '' },
        { text: 'SISTEMA', value: 'system_name', align: '' },
        { text: 'MÓDULO', value: 'module', align: '' },
        { text: 'FUNÇÃO DO SISTEMA', value: 'function_name', align: '' },
        { text: 'LINHA', value: 'line', align: '' },
      ],

      filteredDataWithoutPagination: [],
      usersList: [],
      filteredData: [],
      expanded: [],
      items: [],
      ExpandedItemById: [],
    }
  },
  computed: {
    filterButtonColor() {
      if (!this.isDark) {
        return this.isFiltered ? 'success' : 'black'
      }

      return this.isFiltered ? 'success' : 'white'
    },
    isDark() {
      const $vuetify = getVuetify()

      return $vuetify.theme.dark
    },
    computedSrc() {
      return this.isDark ? noDataImageDark : noDataImageLight
    },
  },
  created() {
    this.usersList = localStorageSlim.get('listEmployees', { decrypt: true })
  },
  methods: {
    toggleRow(item) {
      const index = this.expanded.indexOf(item)
      if (index > -1) {
        this.expanded.splice(index, 1)
      } else {
        this.expanded.push(item)
      }
    },
    hasNonEmptyKey(obj) {
      return Object.values(obj).some(value => {
        if (Array.isArray(value)) {
          return value.length > 0
        }

        return value !== null && value !== undefined && value !== ''
      })
    },
    async onOptionsUpdate(options) {
      this.options = options
      await this.getFilteredDataPaginated()
    },
    async getFilteredDataPaginated() {
      if (this.isFiltered) {
        this.isLoading = true
        const { page } = this.options
        try {
          const response = await axiosNewInstance.post(`/api/v1/utilities/logs/error/filter?page=${page}`, this.filteredDataBody)
          this.filteredData = response.data.data
          this.status = response.status
          this.totalItems = response.data.total
          this.isLoading = false
        } catch (error) {
          this.$swal({
            icon: 'error',
            title: 'Erro ao carregar os dados! Contacte a equipe de desenvolvimento.',
            text: error,
          })
          this.isLoading = false
        }
      }
    },
    getInitials(user) {
      if (!user || !user.name) {
        return '-'
      }
      const names = user.name.split(' ')
      if (names.length === 1) {
        return names[0].charAt(0).toUpperCase()
      }
      const firstInitial = names[0].charAt(0).toUpperCase()
      const lastInitial = names[names.length - 1].charAt(0).toUpperCase()

      return firstInitial + lastInitial
    },
    clearBtn() {
      this.filteredData = ''
      this.filteredDataBody = ''
      this.isFiltered = false
      this.$refs.ModalFilterErrorLogs.clearModal()
      this.status = 0
      this.totalItems = 0
      this.options.page = 1
      this.options.itemsPerPage = 15
    },
    async exportCsv() {
      this.isLoading = true
      if (this.hasNonEmptyKey(this.filteredDataBody)) {
        try {
          const response = await axiosNewInstance.post('/api/v1/utilities/logs/error/filter_without_pagination', this.filteredDataBody)
          this.filteredDataWithoutPagination = response.data
          this.isLoading = false
          this.closeModal()
        } catch (error) {
          this.$swal({
            icon: 'error',
            title: 'Erro ao filtrar! Contacte a equipe de desenvolvimento.',
            text: error,
          })
          this.isLoading = false
        }
      } else {
        this.showMessage({ title: 'Campos vazios', text: 'Você deve preencher ao menos um dos campos' })
        this.isLoading = false
      }
      const file = await this.getCsv()
      if (file) {
        saveAs(file)
      }
    },
    async getCsv() {
      this.isLoadingExport = true

      const workbook = new ExcelJS.Workbook()
      const worksheet = workbook.addWorksheet('sheet')

      worksheet.columns = [
        { header: 'Usuário', key: 'user.name' },
        { header: 'Data e Hora', key: 'created_at' },
        { header: 'Sistema', key: 'system_name' },
        { header: 'Módulo', key: 'module' },
        { header: 'Função', key: 'function_name' },
        { header: 'Linha', key: 'line' },
        { header: 'Arquivo', key: 'file' },
        { header: 'Exceção', key: 'error_message' },
        { header: 'Mensagem de erro', key: 'error_message' },
      ]
      this.filteredDataWithoutPagination?.map(item => {
        worksheet.addRow([
          item.user?.name,
          this.dateFormat(item.created_at),
          item.system_name,
          item.module,
          item.function_name,
          item.line,
          item.file,
          item.exception,
          item.error_message,
        ])
      })

      let blob = null
      await workbook.xlsx.writeBuffer().then(data => {
        blob = new File([data], 'Logs - Erro', {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        })
      })
      this.isLoadingExport = false

      return blob
    },
    formatPeriod() {
      if (!this.filteredDataBody.initial_date && !this.filteredDataBody.final_date) {
        return '00/00/0000 - 00/00/0000'
      }
      const initialDate = this.filteredDataBody.initial_date ? moment(this.filteredDataBody.initial_date).format('DD/MM/YYYY') : moment(this.filteredDataBody.final_date).format('DD/MM/YYYY')
      const finalDate = this.filteredDataBody.final_date ? moment(this.filteredDataBody.final_date).format('DD/MM/YYYY') : moment().format('DD/MM/YYYY')

      return `${initialDate} - ${finalDate}`
    },
    openModalFilterErrorLogs() {
      this.options.page = 1
      this.options.itemsPerPage = 15
      this.ModalFilterErrorLogsIsOpen = true
    },
    async fetchExpandedItemById({ item }) {
      this.loadingItem = false
      try {
        const res = await axiosNewInstance.get(`/api/v1/utilities/logs/error/edit/${item.id}`)
        this.$set(item, 'expandedItemData', res.data.data)
        this.loadingItem = true
      } catch (error) {
        console.log(error)
        this.$swal({
          icon: 'error',
          title: 'Erro ao filtrar! Contacte a equipe de desenvolvimento.',
          text: error,
        })
        this.loadingItem = false
      }
    },
  },
}
</script>

<style scoped>
.result-container {
  background: #1f1d31;
  border-radius: 10px;
  height: auto;
  max-width: 1023px;
  word-wrap: break-word;
  transition: max-height 0.3s ease-in-out;
  overflow: hidden;
}

.stack-trace {
  display: -webkit-box;
  line-clamp: 2;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  max-width: 900px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.stack-trace.expanded {
  display: block !important;
  line-clamp: none;
  -webkit-line-clamp: none !important;
  overflow: visible !important;
  text-overflow: initial !important;
  white-space: normal !important;
  max-width: 100% !important;
}

.stack-trace:hover {
  transform: scale(102%) !important;

}
.expanded:hover {
  transform: scale(100%) !important;

}
.dark-mode {
  color: #e7e3fc;
  font-size: 14px;
  font-weight: 500
}
.light-mode {
  color: #312d4b;
  font-size: 14px;
  font-weight: 500
}
.button-text-logs{
  font-size: 14px;
  font-weight: 600;
}

</style>

<style>
.v-data-footer__select{
  display: none;
}
</style>
