<template>
  <v-card style="">
    <div class="d-flex align-center justify-center pt-2">
      <v-card-title
        primary-title
        style="margin-left: 6.5em;"
      >
        Filtro log de erro
      </v-card-title>
      <v-icon
        size="30"
        class="pb-1"
        style="margin-left: 3.5em;"
        @click="closeModal"
      >
        {{ icons.mdiClose }}
      </v-icon>
    </div>
    <v-card-text class="px-7">
      <Separator label="Filtrar dados" />
      <v-form>
        <v-container class="pa-0 ma-0">
          <v-row>
            <v-col
              cols="12"
              class="pb-0 mb-0 pt-10"
            >
              <v-autocomplete
                v-model="Modules"
                chips
                :items="modulesList"
                small-chips
                clearable
                multiple
                label="Módulo"
                hide-details
                class=" pb-5"
                dense
                outlined
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
              class="py-0 my-0"
            >
              <div
                class="pb-5 d-flex flex-column"
                style="gap: 10px;"
              >
                <v-autocomplete
                  v-model="systemFunction"
                  chips
                  :items="functionsList"
                  small-chips
                  clearable
                  multiple
                  label="Função do sistema"
                  dense
                  outlined
                  hide-details
                ></v-autocomplete>
                <span v-if="systemFunction[0] == 'Outros' ">⚠️ Ao selecionar “outros” os demais filtros são desabilitados!</span>
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
              class="py-0 my-0"
            >
              <v-autocomplete
                v-model="Users"
                chips
                :items="usersList"
                small-chips
                clearable
                multiple
                item-text="name"
                item-value="id"
                label="Usuário"
                dense
                outlined
                hide-details
                class="pb-5"
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="6"
              class="py-0 my-0"
            >
              <v-menu
                ref="menuStart"
                v-model="menuStart"
                :close-on-content-click="false"
                :return-value.sync="startDate"
                transition="scale-transition"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    :value="formattedStartDate"
                    label="Data inicial"
                    style="height: 62px"
                    dense
                    outlined
                    readonly
                    v-bind="attrs"
                    :append-icon="icons.mdiCalendar"
                    hide-details
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="startDate"
                  locale="pt-br"
                  no-title
                  scrollable
                  @change="(date) => { $refs.menuStart.save(date); startDate = date; }"
                >
                  <v-spacer></v-spacer>
                  <v-btn
                    text
                    color="primary"
                    @click="menuStart = false"
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.menuStart.save(startDate)"
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col
              cols="6"
              class="py-0 my-0"
            >
              <v-menu
                ref="menuEnd"
                v-model="menuEnd"
                :close-on-content-click="false"
                :return-value.sync="endDate"
                transition="scale-transition"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    :value="formattedEndDate"
                    label="Data final"
                    style="height: 62px"
                    dense
                    outlined
                    readonly
                    v-bind="attrs"
                    :append-icon="icons.mdiCalendar"
                    hide-details
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="endDate"
                  locale="pt-br"
                  no-title
                  scrollable
                  @change="(date) => { $refs.menuEnd.save(date); endDate = date; }"
                >
                  <v-spacer></v-spacer>
                  <v-btn
                    text
                    color="primary"
                    @click="menuEnd = false"
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.menuEnd.save(endDate)"
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </v-card-text>
    <v-card-actions class="px-7 pb-10">
      <v-btn
        v-if="hasFilters"
        outlined
        color="error"
        :disabled="isLoading"
        @click="clearModal"
      >
        <div
          class="d-flex align-end justify-center pb-1"
          style="gap: 10px;"
        >
          <v-icon size="22">
            {{ icons.mdiFilterVariantRemove }}
          </v-icon>
          <span style="font-weight: 600; font-size: 14px;">LIMPAR</span>
        </div>
      </v-btn>
      <v-spacer />
      <v-btn
        color="info"
        :disabled="isLoading"
        @click="sendFilteredData"
      >
        <span v-if="!isLoading">FILTRAR</span>
        <v-progress-circular
          v-else
          color="success"
          indeterminate
        ></v-progress-circular>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import Separator from '@/components/Separator.vue'
import messages from '@/plugins/showMessageMixin'
import { mdiCalendar, mdiClose, mdiFilterVariantRemove } from '@mdi/js'
import moment from 'moment'

export default {
  name: 'ModalFilterErrorLogs',
  components: {
    Separator,
  },
  mixins: [messages],
  props: {
    usersList: {
      type: [Array, Object],
      required: true,
    },
  },
  data() {
    return {

      startDate: null,
      endDate: null,
      menuStart: false,
      menuEnd: false,
      isLoading: false,

      modulesList: [
        'Adm Motoca Systems',
        'Departamento Pessoal',
        'Integrador',
        'Nexus',
        'SGQ',
        'Smart Query',
        'Up Nexus',
        'Utilitários',
        'Outros',
      ],
      functionsList: [
        'Editar',
        'Excluir',
        'Informação',
        'Inserir',
        'Login',
        'Outros',
      ],

      Modules: [],
      systemFunction: [],
      Users: [],

      icons: {
        mdiClose,
        mdiCalendar,
        mdiFilterVariantRemove,
      },

    }
  },
  computed: {
    formattedStartDate() {
      return this.startDate ? moment(this.startDate).format('DD/MM/YYYY') : ''
    },
    formattedEndDate() {
      return this.endDate ? moment(this.endDate).format('DD/MM/YYYY') : ''
    },
    hasFilters() {
      return this.Modules.length || this.systemFunction.length || this.Users.length || this.startDate || this.endDate
    },
  },
  watch: {
    systemFunction(newValue) {
      if (this.systemFunction[0] === 'Outros' && this.systemFunction[1] != null) {
        this.systemFunction = ['Outros']
      }
      if (this.systemFunction[0] === 'Outros') return

      if (newValue.includes('Outros')) {
        this.systemFunction = ['Outros']
      }
    },
  },
  methods: {
    hasNonEmptyKey(obj) {
      return Object.values(obj).some(value => {
        if (Array.isArray(value)) {
          return value.length > 0
        }

        return value !== null && value !== undefined && value !== ''
      })
    },
    closeModal() {
      this.$emit('close')
    },
    clearModal() {
      this.Modules = []
      this.systemFunction = []
      this.Users = []
      this.startDate = null
      this.endDate = null
      this.$emit('isFiltered', this.isFiltered = false)
      this.$emit('filteredData', [])
    },
    sendFilteredData() {
      const body = {
        file: this.Modules,
        function_name: this.systemFunction,
        initial_date: this.startDate,
        final_date: this.endDate,
        user_id: this.Users,
      }
      console.log(body)
      if (this.hasNonEmptyKey(body)) {
        this.closeModal()
        this.$emit('isFiltered', this.isFiltered = true)
        this.$emit('filteredDataBody', body)
        this.$emit('getFilter')
      } else {
        this.showMessage({ title: 'Campos vazios', text: 'Você deve preencher ao menos um dos campos' })
        this.isLoading = false
      }
    },
  },
}
</script>

<style scoped>
/* Adicione seus estilos aqui */
</style>
